import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import ContentContainer from "../components/ContentContainer"
import TextContainer from "../components/TextContainer"

const CookiesPage = () => {
  return (
    <Layout>
      <Seo title="Polityka cookies" />
      <ContentContainer>
        <TextContainer>
          <h1>Polityka cookies</h1>
          <h2>Czym są "cookies"?</h2>
          <p>
            Cookies to informacje, które odwiedzana witryna internetowa może
            przechować w Twojej przeglądarce i użyć ich w trakcie obecnej
            wizyty, a także w ciągu twoich kolejnych wizyt w tej witrynie.
          </p>
          <h2>Do czego używamy cookies?</h2>
          <p>
            Nasza witryna będzie działał poprawnie, gdy wyłączysz obsługę plików
            cookies - możesz to zrobić w ustawieniach swojej przeglądarki. Nasze
            strona wykorzystuje je do poprawnego działania narzędzi
            analitycznych, takich jak Google Analytics, Sentry oraz działania usługi czatu. 
          </p>
        </TextContainer>
      </ContentContainer>
    </Layout>
  )
}

export default CookiesPage
